
import { ref, watch } from 'vue';
import CardTable from '@/components/kt-datatable/CardTable.vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import ApiService from '@/core/services/ApiService';
import { hideModal } from '@/core/helpers/dom';
import { reinitializeComponents } from '@/core/plugins/keenthemes';

export default {
  name: 'FunctionLists',
  components: {
    CardTable,
  },
  setup() {
    const breadcrumbs = ['Car & Function', ['Function Lists']];
    const tableHeader = [
      {
        key: 'no',
        name: 'No.',
        sortable: true,
      },
      {
        name: 'Name',
        key: 'name',
        sortable: true,
      },
      {
        name: 'Type',
        key: 'type',
        sortable: true,
      },
      {
        name: 'Actions',
        key: 'actions',
        sortable: false,
      },
    ];
    const currentIndex = ref(-1);
    const inputRef = ref({
      name: '',
      type: '',
    });
    const modalRef = ref(null);
    const tableRef = ref<any>(null);
    const tableData = ref<any>([]);
    const loading = ref(true);
    const functionTypes = ref<any>([]);

    watch(
      () => [tableRef.value?.rowsPerPage, tableRef.value?.currentPage],
      async ([rows, page]) => {
        loading.value = true;

        const funcData = await ApiService.get(
          `function-of-car?page=${page}&pageSize=${rows}`
        );
        tableData.value = funcData.data.datas;
        tableRef.value.total = funcData.data.total;

        if (!functionTypes.value.length) {
          functionTypes.value.push([
            {
              name: 'Function Type',
              key: 'type',
            },
            {
              id: 'Fuel',
              name: 'Fuel',
            },
            // {
            //   id: 'Gps',
            //   name: 'Gps',
            // },
            {
              id: 'Option',
              name: 'Option',
            },
          ]);
        }

        reinitializeComponents();
        loading.value = false;
      }
    );

    const create = async () => {
      await ApiService.post('function-of-car', {
        name: inputRef.value.name,
        type: inputRef.value.type,
      });

      hideModal(modalRef.value);

      Swal.fire({
        text: 'New Function has been created',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Return to Function Lists',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      });

      inputRef.value = {
        name: '',
        type: '',
      };

      await tableRef.value.getData(
        tableRef.value.currentPage,
        tableRef.value.rowsPerPage
      );

      reinitializeComponents();
    };

    return {
      tableHeader,
      breadcrumbs,
      currentIndex,
      inputRef,
      modalRef,
      create,
      tableRef,
      functionTypes,
      tableData,
      loading,
    };
  },
};
